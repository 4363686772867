<template>
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div
      class="login login-4 login-signin-on d-flex flex-row-fluid"
      id="kt_login"
    >
      <div
        class="
          d-flex
          flex-center flex-row-fluid
          bgi-size-cover bgi-position-top bgi-no-repeat
        "
        style="background-image: url('media/bg/bg-3.jpg')"
      >
        <div
          class="login-form text-center p-7 position-relative overflow-hidden"
        >
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#">
              <img src="logo.png" style="width: 250px;" alt="" />
            </a>
          </div>
          <!--end::Login Header-->
          <!--begin::Login Sign in form-->
          <div class="login-signin">
            <div class="mb-20">
              <h3>เข้าระบบ หลังบ้าน</h3>
              <div class="text-muted font-weight-bold">
                กรอก ชื่อผู้ใช้งาน กับ รหัสผ่าน ของท่านเพื่อเข้าใช้งานระบบ.
              </div>
            </div>
            <Form @submit="onSubmitLogin" :validation-schema="login">
              <div class="form-group mb-5">
                <Field
                  name="username"
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  placeholder="ชื่อผู้ใช้"
                  autocomplete="off"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="username" />
                  </div>
                </div>
              </div>
              <div class="form-group mb-5">
                <Field
                  name="password"
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="password"
                  placeholder="รหัสผ่าน"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </div>
              <!--begin::Submit button-->
              <button
                type="submit"
                ref="submitButton"
                id="kt_sign_in_submit"
                class="btn btn-lg btn-primary w-100 mb-5"
              >
                <span class="indicator-label"> เข้าใช้งาน </span>

                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Submit button-->
            </Form>
          </div>
          <!--end::Login Sign in form-->
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { LOGIN, LOGOUT } from "@/store/modules/AuthModule";
import { SET_STATUS } from "@/store/modules/LoginModule";
import useSweetalert from "@/core/helpers/sweetalert2";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
export default defineComponent({
  name: "Login",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { SconfirmNc } = useSweetalert();
    const submitButton = ref(null);
    const login = Yup.object().shape({
      username: Yup.string().required().label("Username"),
      password: Yup.string().min(6).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        submitButton.value.setAttribute("disabled", "disabled");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(LOGIN, values)
          .then(() => {
            SconfirmNc(
              "เข้าสู่ระบบ สำเร็จ",
              "success",
              "เข้าสู่หน้าหลัก !"
            ).then(function () {
              // Go to page after successfully login
              store.dispatch(SET_STATUS, true);
              router.push({ name: "dashboard" });
            });
          })
          .catch(() => {
            SconfirmNc(
              "ชื่อผู้ใช้งาน หรือ รหัสผ่าน ไม่ถูกต้อง",
              "error",
              "ลองอีกครั้ง !"
            );
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
        submitButton.value?.removeAttribute("disabled");
      }, 2000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
</script>

<style></style>
